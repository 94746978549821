<template>
  <div>


    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--home-banner-->

    <section>
      <swiper class="swiper home-slider" :options="swiperOption">

        <swiper-slide>
          <div class="items bg-grey-1">
            <div class="container">
              <div class="row ">
                <div
                    class="col-lg-5 d-flex d-lg-block flex-wrap justify-content-center left-section text-center text-lg-left overflow-hidden">
                  <div class="col-12 p-0">
                    <p class="banner-text-1 text-uppercase">Automate your house to a </p>
                    <p class="banner-text-2 text-uppercase">better home.</p>
                    <p class="mr-lg-4 mb-0 pr-lg-5 text-white">Upgrade your home into a better place where things are
                      custom-built just to make it a better place to be.Have the comfort and convenience at a single
                      soft touch.</p>
                  </div>
                  <div class="col-7 p-0 mt-4">
                    <img class="switch-img" alt="#" src="./assets/img/banner-bgi-2.png"/>
                  </div>
                  <div class="col-lg-10 p-0 mt-4">
                    <ul class="list-unstyled row banner-features">
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Mobile.png"/>
                        <p>Mobile <br> Compatibility</p></li>
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Wifi.png"/>
                        <p>Wifi <br> Portability</p></li>
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Security.png"/>
                        <p>High <br> Security</p></li>
                    </ul>
                  </div>
                  <div class="col p-0 mt-4">
                    <router-link class="btn xeo-button-2" to="/products">Explore Products</router-link>
                  </div>

                  <img class="pot-img" alt="#" src="./assets/img/banner-bgi-3.png"/>
                </div>
                <div class="col-lg-7 d-lg-block d-none pl-0 right-section">
                  <img alt="#" src="./assets/img/banner-bgi-1.jpg"/>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="items bg-grey-1">
            <div class="container">
              <div class="row">
                <div
                    class="col-lg-5 d-flex d-lg-block flex-wrap justify-content-center left-section text-center text-lg-left overflow-hidden">
                  <div class="col-12 p-0">
                    <p class="banner-text-1 text-uppercase">Walk into a world that</p>
                    <p class="banner-text-2 text-uppercase">understands you.</p>
                    <p class="mb-0 text-white">Explore a world that lets you create an ambience just to
                      meet up with your mood and expressions as well. Have it spun around just the way that you had it
                      in your mind. A design that can aesthetically blend into any interior decor.
                    </p>
                  </div>
                  <div class="col-7 p-0 mt-4 mt-lg-3">
                    <img class="switch-img" alt="#" src="./assets/img/banner-bgi-2.png"/>
                  </div>
                  <div class="col-lg-10 p-0 mt-4 mt-lg-3">
                    <ul class="list-unstyled row banner-features">
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Mobile.png"/>
                        <p>Mobile <br> Compatibility</p></li>
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Wifi.png"/>
                        <p>Wifi <br> Portability</p></li>
                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Security.png"/>
                        <p>High <br> Security</p></li>
                    </ul>
                  </div>
                  <div class="col p-0 mt-4 mt-lg-3">
                    <button class="btn xeo-button-2 ">Explore Products</button>
                  </div>

                  <img class="pot-img" alt="#" src="./assets/img/banner-bgi-3.png"/>
                </div>
                <div class="col-lg-7 d-lg-block d-none pl-0 right-section">
                  <img alt="#" src="./assets/img/banner-bgi-1.jpg"/>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!--        <swiper-slide>-->
        <!--          <div class="items bg-grey-1">-->
        <!--            <div class="container">-->
        <!--              <div class="row">-->
        <!--                <div-->
        <!--                    class="col-lg-5 d-flex flex-wrap left-section justify-content-center justify-content-lg-between text-center text-lg-left">-->
        <!--                  <div class="col-12 p-0">-->
        <!--                    <p class="banner-text-1">LET SIMPLE ACTIONS</p>-->
        <!--                    <p class="banner-text-2">LOOK SMART.</p>-->
        <!--                    <p class="mr-lg-4 mb-0 pr-lg-5 text-white">We at Xeoscript arevet people passionate about-->
        <!--                      data. We-->
        <!--                      understand how data is get more reliable important for how data is ge you top put up-->
        <!--                      with achieve success in business.</p>-->
        <!--                  </div>-->
        <!--                  <div class="col-7 p-0 mt-4">-->
        <!--                    <img class="switch-img" alt="#" src="./assets/img/banner-bgi-2.png"/>-->
        <!--                  </div>-->
        <!--                  <div class="col-lg-10 p-0 mt-4">-->
        <!--                    <ul class="list-unstyled row banner-features">-->
        <!--                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Mobile.png"/>-->
        <!--                        <p>Mobile <br> Compatibility</p></li>-->
        <!--                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Wifi.png"/>-->
        <!--                        <p>Wifi <br> Portability</p></li>-->
        <!--                      <li class="col-4"><img alt="#" src="./assets/img/main-banner/Security.png"/>-->
        <!--                        <p>High <br> Security</p></li>-->
        <!--                    </ul>-->
        <!--                  </div>-->
        <!--                  <div class="col p-0 mt-4">-->
        <!--                    <button class="btn xeo-button-2 ">Explore Products</button>-->
        <!--                  </div>-->

        <!--                  <img class="pot-img" alt="#" src="./assets/img/banner-bgi-3.png"/>-->
        <!--                </div>-->
        <!--                <div class="col-lg-7 pl-0 d-lg-flex flex-wrap d-none right-section">-->
        <!--                  <img alt="#" src="./assets/img/banner-bgi-1.jpg"/>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->

        <div class="swiper-pagination" slot="pagination"></div>

      </swiper>
    </section>

    <!--home-banner-->


    <section>
      <div class="container my-5">
        <div class="row">
          <div class="col-12 text-center px-lg-5">
            <p class="xeo-heading-3 px-lg-5">Creating IoT products
              <br> that are high-tech and innovative</p>
            <p class="px-lg-5 poppins-regular">A cutting edge product that is sure to amaze everyone equally with its
              high-tech and affordable rates as well as its creative and smart look, leaving an unparalleled experience
              to the customer.</p>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="product-banner">
        <img class="img-fluid d-none d-lg-block w-100" alt="#" src="././assets/img/banner-middle.jpg"/>
        <img alt="#" class="img-fluid d-lg-none w-100" src="./assets/img/banner-img.jpg"/>
        <div class="row">
          <div class="col-lg-7 col-md-7 col-12 text-center text-lg-left px-lg-5 product-banner-content text-white">
            <p class="xeo-heading-3 px-lg-5 ">Tell us requirement and <br> get a free quote now!</p>
            <p class="px-lg-5 poppins-regular">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab
              accusantium commodi cum cupiditate</p>
            <div class="col p-0 px-lg-5 mt-3 mt-lg-4">
              <router-link class="btn xeo-button-2" to="/getaquote">Get a free quote!</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="container my-5">
        <div class="row">
          <div class="col-12 text-center">
            <p class="xeo-heading-3">Our Products</p>
            <p class="mb-0 poppins-regular mx-xl-5">These home automation products are creative, intelligent and make a
              change
              in the way we live. Comfort and convenience are sure to indulge you with a feeling like never before. The
              products are not only smart but also intuitive as well. All our products are a promise to make your dream
              come true.</p>

          </div>
          <div class="col p-0 position-relative product-slider">
            <swiper class="swiper mt-3 mb-5" :options="swiperOption1">

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/01.jpg"/>
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">One Switch</p>
                          <p class="poppins-regular">
                            This switch is a single switch that supports a single load that is in-built. It also
                            provides secure access along with an easy setup. It is compatible with both Android and iOS.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/03.jpg"/>
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">Bell Switch</p>
                          <p class="poppins-regular">
                            This sleek switch made of glass with a polished look is sure to make a style statement by
                            itself. Not to forget its iconic and appealing feature that will sure leave your guests
                            mesmerised.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/05.jpg"/>
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">Two Switch</p>
                          <p class="poppins-regular">
                            The switch has an on-off control that is in-built and supports 2 individual loads. It also
                            provides secure access along with an easy setup.
                          </p>
                          <a class="xeo-button-3" href="#">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/07.jpg">
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">Switch with Bell</p>
                          <p class="poppins-regular">
                            This switch provides in-built on-off control for a single load that is in-built along with
                            the Bell.
                          </p>
                          <a class="xeo-button-3" href="#">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/09.jpg">
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">4 Switch with wifi</p>
                          <p class="poppins-regular">
                            This Switch offers in-built on-off control for 4 individual loads. The switch works with
                            both Android and iOS user applications. It also provides secure access along with an easy
                            setup.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/11.jpg">
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">4 Switch without wifi</p>
                          <p class="poppins-regular">
                            This product offers in-built on-off control for 4 individual loads. It also provides secure
                            access along with an easy setup. But it doesn’t support wifi.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/13.jpg">
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">8 Switch with wifi</p>
                          <p class="poppins-regular">
                            This product offers an in-built on-off control for 8 individual loads. The switch works with
                            both Android and iOS user applications. It also provides secure access along with an easy
                            setup. It also supports wifi.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="product-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/products/15.jpg">
                        <div class="product-slider-details">
                          <p class="xeo-heading-4">8 Switch without wifi</p>
                          <p class="poppins-regular">
                            This product offers an in-built on-off control for 8 individual loads. It also provides
                            secure access along with an easy
                            setup. But it doesn’t support wifi.
                          </p>
                          <router-link to="/products/" class="xeo-button-3">Know more
                            <img alt="#" src="./assets/img/know-more.png"/>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <!--<div class="swiper-pagination" slot="pagination"></div>-->

            </swiper>
            <div class="swiper-button-p nav-btn prev-slide" slot="button-prev"></div>
            <div class="swiper-button-n nav-btn next-slide" slot="button-next"></div>
          </div>

          <div class="row m-0 w-100 text-center my-4 justify-content-center">
            <div class="col-6 col-lg-3 col-md-3 col-xl-2 col-sm-4">
              <router-link class="btn btn-lg-block xeo-button-1" to="/products">View All Products</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="container my-5 c-overflow-hide c-overflow-lg-show">
        <div class="row">
          <div class="col-12 text-center">
            <p class="xeo-heading-3">Gallery</p>
            <p class="poppins-regular mx-xl-5">Take a look at our gallery and experience the vibe that we have promised
              you with our innovative and sophisticated products. And experience how well our products will maximize
              your comfort as well as your convenience.</p>
          </div>
          <div class="position-relative  mt-5 gallery-slider gallery-slider-w">
            <swiper class="swiper mt-3 mb-5" :options="swiperOption2">
              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="gallery-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="gallery-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="gallery-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="gallery-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="items">
                  <div class="row m-0">
                    <div class="col-12">
                      <div class="gallery-slider-container my-2 my-lg-1">
                        <img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-p nav-btn prev-slide" slot="button-prev"></div>
            <div class="swiper-button-n nav-btn next-slide" slot="button-next"></div>
          </div>
          <div class="row m-0 w-100 text-center my-4 justify-content-center">
            <div class="col-6 col-lg-3 col-md-3 col-xl-2 col-sm-4">
              <router-link class="btn btn-lg-block xeo-button-1" to="/gallery">View Gallery</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="chooseus-bg-pos">
      <img class="img-fluid chooseus-bg" alt="#" src="./assets/img/choose_bgi.png"/>
      <div class="container py-5 position-relative">
        <div class="row">
          <div class="col-12 text-center">
            <p class="xeo-heading-3">Why Should You Choose Us?</p>
            <p class="poppins-regular my-xl-5">We are one of the most trustworthy and famous brands all across the
              world.
              The products that we present to you are high-tech but affordable that is sure to bring a smile to your
              face. We also ensure to create a customized experience as per the client requirement.</p>
          </div>
          <div class="col-12">
            <div class="chooseus mt-3 row ml-lg-0">

              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/latest-technology.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Intelligent</p>
                    <p class="poppins-regular">
                      The switches are intelligent by themselves, therefore requires only simple programming steps. It
                      provides you with the privilege of scheduling the electrical appliances and devices as per your
                      taste.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/iot-solutions.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Seamless user interface</p>
                    <p class="poppins-regular">
                      The switches are meant to make life simpler with their simple and easy operation. You can be in
                      charge of all your automated appliances from just about anywhere in the world. It will also let
                      you customise the ambience with one single soft touch.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/latest-technology2.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Smart and pocket friendly</p>
                    <p class="poppins-regular">
                      The costs are very much pocket friendly and won’t leave you with a chance of rethinking about the
                      same. There would not be any kind of spending on rewiring or renovation as all you would have to
                      do is just replace the existing switches.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/iot-solutions2.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Reliable and Service Support</p>
                    <p class="poppins-regular">
                      We are a team that our clients can rely on forever and expect service support for the product if
                      need arises. Any added services can be provided by our dedicated support team, so that things get
                      simpler and easy for our valuable clients by all means.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!--mailer-->

    <Mailer/>

    <!--mailer-->


    <!--footer-->

    <Footer id="oneSwitch"/>

    <!--footer-->

  </div>
</template>

<script>
import Header from "@/components/header";
import Subnav from "@/components/subnav";
import Footer from "@/components/footer";
import Mailer from "@/components/mailer";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "index",
  components: {
    Mailer,
    Footer,
    Subnav,
    Header,
    Swiper,
    SwiperSlide
  }, data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: true,
        // autoplay: {
        //   delay: 4500,
        //   disableOnInteraction: false
        // },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      },
      swiperOption1: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-n',
          prevEl: '.swiper-button-p',
        },
        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      },
      swiperOption2: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-n',
          prevEl: '.swiper-button-p',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      }
    }
  }
}

</script>

<style scoped>
:root {
  --swiper-theme-color: black;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../index.vue'
import About from '../about.vue'
import Products from '../product.vue'
import Contact from '../contact.vue'
import Gallery from '../gallery.vue'
import Getaquote from '../getaquote.vue'
import Features from '../features.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about/',
        name: 'About',
        component: About
    },
    {
        path: '/products/',
        name: 'Products',
        component: Products
    },
    {
        path: '/contact/',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/gallery/',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/getaquote/',
        name: 'Getaquote',
        component: Getaquote
    },
    {
        path: '/features/',
        name: 'Features',
        component: Features
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router

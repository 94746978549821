<template>

  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="About Us"/>

    <!--headingbanner-->


    <section class="c-overflow-hide">
      <div class="container my-5 ">
        <div class="row">
          <div class="col-12 text-center mb-4">
            <p class="xeo-heading-3">Why Lemon Blue...??</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-4 mb-lg-0">
            <img class="about-banner-2" alt="#" src="./assets/img/about-us.png"/>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first">
            <ul class="xeo-list-1">
              <li><b>World-Class Products along with services for smart and secure homes.</b></li>
              <li><b>Control and access from anywhere around the world.</b></li>
              <li><b>Support & After-Sales: National and international network of system integration and patterns.</b>
              </li>
              <li><b>Beautify your home space within a matter of minutes.</b></li>
              <li><b>Hassle-free installation so that you wouldn’t have to rewire, as the installation can be done
                easily.</b></li>
            </ul>
          </div>

        </div>
      </div>
    </section>


    <section class="bg-light-grey-2">
      <div class="container pt-5 about-features-container">
        <div class="row">
          <div class="col-12 text-center mb-4">
            <p class="xeo-heading-3">The Finest and most precise way to beautiful and awe-inspiring <br> Smart Home
              Automation Solutions
            </p>
            <p class="poppins-regular mx-xl-5">Creating innovative designs is what we prioritise, and that is why our
              team
              creates and channels products just to <br class="d-none d-lg-block">ensure that our clients get what they
              deserve.</p>
          </div>
          <div class="col-12">
            <ul class="list-unstyled row about-features">
              <li class="col-6 col-lg-3"><img alt="#" src="./assets/img/styles.png"/>
                <p>Aesthetic<br> Design</p></li>
              <li class="col-6 col-lg-3"><img alt="#" src="./assets/img/affordable.png"/>
                <p>Pocket-friendly<br> price</p></li>
              <li class="col-6 col-lg-3"><img alt="#" src="./assets/img/flexible.png"/>
                <p>Seamless user<br> interface</p></li>
              <li class="col-6 col-lg-3"><img alt="#" src="./assets/img/user.png"/>
                <p>Intelligent <br></p></li>
            </ul>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="container py-5 position-relative">
        <div class="row">
          <div class="col-12 text-center ">
            <p class="xeo-heading-3">A Professionally managed company that has been delivering embedded solutions by
              bringing in products with cutting-edge technologies.
              <br class="d-none d-lg-block">
            </p>
            <p class="px-lg-5 poppins-regular">Our workforce is built with employees who have proved their efficiency
              and skills by providing excellent service to our clients along with delivering fine-tuned quality
              management processes. Our world-class products and services have been tailor-made to fit the client
              requirements. This gives us an upper hand to be in the frontline of the highly competitive
              marketplace.</p>
          </div>
          <div class="col-12">
            <div class="aboutus-auto mt-3 row ml-lg-0">

              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="aboutus-auto-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/bg-automation-01.png"/>
                  <div class="aboutus-auto-details">
                    <p class="xeo-heading-4">Home Security</p>
                    <p class="poppins-regular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam
                      blanditiis fuga id non omnis, possimus qui quis
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="aboutus-auto-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/bg-automation-02.png"/>
                  <div class="aboutus-auto-details">
                    <p class="xeo-heading-4">Lightning</p>
                    <p class="poppins-regular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam
                      blanditiis fuga id non omnis, possimus qui quis
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="aboutus-auto-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/bg-automation-03.png"/>
                  <div class="aboutus-auto-details">
                    <p class="xeo-heading-4">Interactive Home</p>
                    <p class="poppins-regular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam
                      blanditiis fuga id non omnis, possimus qui quis
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="aboutus-auto-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/bg-automation-04.png"/>
                  <div class="aboutus-auto-details">
                    <p class="xeo-heading-4">Modern Automation</p>
                    <p class="poppins-regular">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam
                      blanditiis fuga id non omnis, possimus qui quis
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <!--mailer-->

    <Mailer/>

    <!--mailer-->

    <!--footer-->

    <Footer/>

    <!--footer-->
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Subnav from "@/components/subnav";
import Header from "@/components/header";
import Headingbanner from "@/components/headingbanner";
import Mailer from "@/components/mailer";

export default {
  name: "about",
  components: {
    Mailer,
    Headingbanner,
    Footer,
    Subnav,
    Header,
  }
}
</script>

<style scoped>

</style>
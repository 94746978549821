<template>
  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="Features"/>

    <!--headingbanner-->

    <section>
      <div class="container my-5">
        <div class="row">
          <div class="col-12 text-center px-lg-5">
            <p class="xeo-heading-3 px-lg-5">Fortuna Smart Switch</p>
            <p class="px-lg-5 poppins-regular">Redefine modern living with our Fortuna Smart switches as they are
              designed to meet the refined taste of our customers. These single touch electrical switches will change
              and complement your existing modern living and give it an out of the world feel. These switches just go
              about anywhere and everywhere, be it wood, glass, acrylic or even wallpaper. Our Fortuna switches are the
              end result of sophisticated designs that have been chosen by highly skilled, experienced and professional
              designers who deliver next-generation products in the industry.
            </p>
          </div>
        </div>
      </div>
    </section>


    <section>
      <img class="img-fluid d-none d-lg-block" src="./assets/img/img1.jpg" alt="#">
      <img class="img-fluid d-lg-none" src="./assets/img/features-img1.jpg" alt="#">
    </section>


    <section>
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12 text-center">
            <p class="xeo-heading-3 mt-3 mb-0">"Changing people's way of life"</p>
            <p class="xeo-heading-5 mb-3">Find the interesting features of our beautiful Fortuna switches right
              here.</p>
          </div>
        </div>
        <div class="row mb-5 pt-4">
          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <img class="img-fluid" alt="#" src="./assets/img/img2.jpg"/>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first product-details">
            <div>
              <ul class="xeo-list-1">

                <li><b>One-touch operation</b> : One soft touch can do it, be it turning on the lights. Fortuna switches
                  can give you ease of operation.
                </li>

                <li><b>Remote reach out</b> : Remote access is what our wireless technology can provide, be it giving a
                  surprise party to your loved ones, or even creating a stress-free environment.
                </li>

                <li><b>Secure</b> : The Fortuna switches are designed in such a way as to prioritise your safety along
                  with your loved ones. the switchboard prevents any type of mishaps of shocks and sparks, making it
                  safe for even a baby to operate.
                </li>

                <li><b>Easy installation</b> : Be it a new project or renovation, there would be no requirement of extra
                  wiring or so, making it a hassle-free installation.
                </li>

                <li><b>proven intelligence</b> : In today's increasingly smart world, we have made sure that the Fortuna
                  switches are also smart and intelligent enough to match your lifestyle as the switching controls are
                  accessible through your mobile and tablet as well.
                </li>

                <li><b>Aesthetic style</b> : You can glamorise the indicator icons for the Fortuna switches to make it
                  user-friendly and give your home an aesthetic look.
                </li>

                <li><b>Illuminative switches</b> : Our Fortuna switches have an on-off indicator glow that makes it easy
                  to reach out even in darkness.
                </li>

                <li><b>Clean and clear look</b> : The switches are designed to keep away dust and dirt, therefore making
                  it easy to keep it clean and clear always.
                </li>
                <li><b>Smart way to light up your mood</b> : The intelligent lighting control is sure to give you the
                  right ambience, be it for work, entertainment or play.
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </section>


    <section class="chooseus-bg-pos">
      <img class="img-fluid chooseus-bg" alt="#" src="./assets/img/choose_bgi.png"/>
      <div class="container py-5 position-relative">
        <div class="row">
          <div class="col-12 text-center">
            <p class="xeo-heading-3">Smart Way to the digital World.</p>
            <p class="poppins-regular mx-xl-5">The Fortuna switches can add up to the perfect combination of a modern
              lifestyle. Unlike the conventional switches, the Fortuna switches are meant to stand out in the crowd.</p>
          </div>
          <div class="col-12">
            <div class="chooseus mt-3 row ml-lg-0">

              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/latest-technology.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Location</p>
                    <p class="poppins-regular">
                      Be it for your home, office, showroom, banquet halls, conference room or hotel, these switches
                      will always be a great add on.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/iot-solutions.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Unique Attributes</p>
                    <p class="poppins-regular">
                      The intelligence and the innovative look are sure to meet the gazer's eyes, making you a proud
                      owner of the same.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/latest-technology2.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Best affordable prices</p>
                    <p class="poppins-regular">
                      The prices for these great switches are very pocket friendly as you wouldn't have to give them a
                      second thought before choosing them.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="chooseus-container my-2 my-lg-1">
                  <img class="img-fluid" alt="#" src="./assets/img/iot-solutions2.png"/>
                  <div class="chooseus-details">
                    <p class="xeo-heading-4">Sleek designs</p>
                    <p class="poppins-regular">
                      The highlight of these switches again are the sleek designs and it is as if the switches have
                      beauty with brains.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <!--mailer-->

    <Mailer/>

    <!--mailer-->

    <!--footer-->

    <Footer/>

    <!--footer-->
  </div>

</template>

<script>
import Subnav from "@/components/subnav";
import Header from "@/components/header";
import Headingbanner from "@/components/headingbanner";
import Mailer from "@/components/mailer";
import Footer from "@/components/footer";

export default {
  name: "features",
  components: {Footer, Mailer, Headingbanner, Header, Subnav}
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="Get A Quote"/>

    <!--headingbanner-->

    <!--mailer-->

    <Mailer/>

    <!--mailer-->

    <!--footer-->

    <Footer/>

    <!--footer-->
  </div>

</template>

<script>
import Subnav from "@/components/subnav";
import Header from "@/components/header";
import Headingbanner from "@/components/headingbanner";
import Mailer from "@/components/mailer";
import Footer from "@/components/footer";

export default {
  name: "getaquote",
  components: {Footer, Mailer, Headingbanner, Header, Subnav}
}
</script>

<style scoped>

</style>
<template>
  <header class="xeo-header-2">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light justify-content-between  sticky-top">
        <a class="navbar-brand" href="#"><img class="img-fluid" alt="#" src="../assets/img/logo.png"/></a>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" class="flex-grow-0" is-nav>
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">About us</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/features">Features</router-link>

            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/gallery">Gallery</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/products">Products</router-link>

            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact">Contact</router-link>
            </li>
            <li class="pl-3 pr-3 pr-lg-0 text-center">
              <router-link class="btn btn-lg-block xeo-button-1" to="/getaquote">Get A Quote</router-link>
            </li>

          </ul>
        </b-collapse>
        <router-view/>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "header"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="Contact"/>

    <!--headingbanner-->
    <section>
      <div class="container py-5 contact-us text-center text-lg-left">
        <div class="row">
          <div class="col-lg-5">
            <p class="xeo-heading-3">Office Address</p>
            <p class="poppins-regular">
              Lemon Blue Iot Solutions <br>
              Kalarikkal Building, Near HDFC Bank, <br>
              Mannuthy By - Pass, Thrissur, <br>
              Kerala, India 680 651
            </p>
            <div class="">
              <a href="https://goo.gl/maps/iinxFHZhpT8d5pTU7" target="_blank" class="btn mb-5 xeo-button-4">
                <img class="" alt="#" src="./assets/img/locations-contaact.png"/>
                Our Location
              </a>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-lg-6 contact-details">
                <p class="xeo-heading-3 d-none d-lg-block">&ensp;</p>
                <br>
                <p class="">
                  Office: <a href="tel:+91 8943 801 853">+91 89438 01853</a>
                </p>
                <p>
                  HR Enquiry: <a href="tel:+91 96450 89337">+91 96450 89337</a>
                </p>
                <p class="">
                  Email: <a href="mailto:info@lemonblue.com" target="_blank">info@lemonblue.in</a>
                </p>
              </div>
              <div class="col-7 col-lg-4 col-sm-4 m-auto pt-5 m-lg-0">
                <img class="img-fluid" alt="#" src="./assets/img/img_mail.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div class="container py-5 gallery-img-container">
        <div class="row">
          <div class="col-12">
            <div class="mt-3 row ml-lg-0">

              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0 my-2 my-lg-1">
                <div class="gallery-img-wrapper ">
                  <a href="#"><img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/></a>
                  <div class="content-overlay"></div>
                  <div class="overlay">Mountain Lakes</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="gallery-img-wrapper my-2 my-lg-1">
                  <a href="#"><img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/></a>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="gallery-img-wrapper my-2 my-lg-1">
                  <a href="#"><img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/></a>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 pl-lg-0">
                <div class="gallery-img-wrapper my-2 my-lg-1">
                  <a href="#"><img class="img-fluid" alt="#" src="./assets/img/gallery-img.jpg"/></a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>


    <!--mailer-->

    <Mailer/>

    <!--mailer-->

    <!--footer-->

    <Footer/>

    <!--footer-->
  </div>

</template>

<script>
import Subnav from "@/components/subnav";
import Header from "@/components/header";
import Headingbanner from "@/components/headingbanner";
import Mailer from "@/components/mailer";
import Footer from "@/components/footer";

export default {
  name: "contact",
  components: {Footer, Mailer, Headingbanner, Header, Subnav}
}
</script>

<style scoped>

</style>
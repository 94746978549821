<template>
  <footer class="bg-grey-1 text-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 mt-4">
          <img class="footer-logo img-fluid" alt="#" src="../assets/img/logo_white.png"/>
          <p class="mt-4 footer-description">A cutting edge product that is sure to amaze everyone equally with its
            high-tech and affordable rates as well as its creative and smart look, leaving an unparalleled experience to
            the customer.</p>
          <p class="mt-4 poppins-regular d-none d-lg-block">&#xa9; Lemon Blue 2020 All Rights Reserved</p>
        </div>
        <div class="col-5 col-lg-2 mt-4">
          <p class="footer-heading">
            Navigations
          </p>
          <ul class="list-unstyled footer-nav">
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <router-link to="/about">About us</router-link>
            <li>
              <router-link to="/features">Features</router-link>
            <li>
              <router-link to="/gallery">Gallery</router-link>
            <li>
              <router-link to="/products">Products</router-link>
            <li>
              <router-link to="/contact">Contact</router-link>
            </li>
          </ul>
        </div>
        <div class="col-7 col-lg-3 mt-4">
          <p class="footer-heading">
            Contact
          </p>
          <p class="footer-address">
            Lemon Blue IoT Solutions,
            Kalarikkal Building,
            Near HDFC Bank,
            Mannuthy By-pass,
            Thrissur, Kerala,<br>
            India - 680 651
          </p>
        </div>
        <div class="col-12 col-lg-3 mt-4">
          <div class="col-12 p-0">
            <p class="footer-heading">
              Phone
            </p>
            <p>
              <a href="tel:+91 8943 801 853">+91 8943 801 853</a>
              <span class="d-lg-none"> | </span><br class="d-none d-lg-block">
              <a href="tel:+91 8567 789 134"> +91 8567 789 134</a>
            </p>
          </div>
          <div class="col-12 p-0">
            <p class="footer-heading">
              Email
            </p>
            <p><a href="mailto:info@lemonblue.com" target="_blank">info@lemonblue.com</a></p>
          </div>
        </div>

      </div>
    </div>
    <hr class="mt-4 d-none d-lg-block">

    <div class="container">
      <div class="col-12 mt-4 mt-lg-0">
        <div class="footer-social pb-lg-3">
          <ul class="d-flex justify-content-center list-unstyled m-0 social-media">
            <li class="footer-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/fb.png"/></a>
            </li>
            <li class="footer-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/twit.png"/></a>
            </li>
            <li class="footer-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/linkein.png"/></a>
            </li>
            <li class="footer-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/insta.png"/></a>
            </li>
            <li class="footer-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/youtube.png"/></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="mt-4 d-lg-none">

    <div class="container d-lg-none">
      <div class="col-12 text-center footer-copyright">
        <p>&#xa9; Lemon Blue 2020 All Rights Reserved</p>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="Products"/>

    <!--headingbanner-->

    <section>
      <div class="container my-5 ">

        <div class="row mb-5">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/01.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/02.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex product-details">
            <div class="w-100">
              <p class="xeo-heading-3">One Switch</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5 pt-4">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/03.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/04.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first product-details">
            <div class="w-100">
              <p class="xeo-heading-3">Bell Switch</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/05.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/06.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex product-details">
            <div class="w-100">
              <p class="xeo-heading-3">Two Switch</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5 pt-4">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/07.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/08.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first product-details">
            <div class="w-100">
              <p class="xeo-heading-3">Switch with Bell</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/09.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/10.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex product-details">
            <div class="w-100">
              <p class="xeo-heading-3">4 Switch with wifi</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5 pt-4">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/11.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/12.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first product-details">
            <div class="w-100">
              <p class="xeo-heading-3">4 Switch without wifi</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/13.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/14.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex product-details">
            <div class="w-100">
              <p class="xeo-heading-3">8 Switch with wifi</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-5 pt-4">

          <div class="align-items-center col-lg-6 d-flex mb-4 mb-lg-0">
            <swiper class="swiper product-details-slider" :options="swiperOption">
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/15.jpg"/>
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid" alt="#" src="./assets/img/products/16.jpg"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>

          <div class="align-items-center col-lg-6 d-flex order-lg-first product-details">
            <div class="w-100">
              <p class="xeo-heading-3">8 Switch without wifi</p>
              <ul class="xeo-list-1 product-details-container">
                <li><b>Size</b> : 86mm×86×32mm</li>
                <li><b>Item no</b> : L6-HS3</li>
                <li><b>Weight</b> : 0.3kg</li>
                <li><b>Panel</b> : Tempered Crystal Glass</li>
                <li><b>Bottom Materials</b> : Retardant PC</li>
                <li><b>Rated voltage</b> : AC 100-250V 50/60Hz</li>
                <li><b>Rated current/Max Current</b> : 3A/10A</li>
                <li><b>Rated Load</b> : 200W</li>
                <li><b>Load type</b> : Incandescent lamp, energy saving lamp, fluorescent</li>
                <li><b>Wireless Wi-Fi standard</b> : 802.11b/g/n</li>
                <li><b>Wireless Wi-Fi Security</b> : WEP/WPA/WPA2( AES-TKIP-Personal)</li>
                <li><b>Wi-Fi performance Transmission power</b> : +20dBm,11Mbps(CCK)</li>
                <li><b>Wi-Fi performance Receiver sensitivity</b> : -89dBm,11Mbps(CCK)W</li>
                <li><b>Standby Current</b> : 0.001A</li>
                <li><b>Standby power</b> : 0.25W</li>
                <li><b>Operating temperature</b> : -25 degree to +60 degree</li>
                <li><b>Relative humidity</b> : &lt;95%</li>
              </ul>
              <div class="justify-content-center justify-content-lg-start ml-0 row">
                <div class="col-5 col-lg-4 ml-lg-40 pl-0">
                  <button class="btn btn-block xeo-button-2">Get a quote</button>
                </div>
                <div class="col-5 col-lg-4 pl-0">
                  <button class="btn btn-block xeo-button-1">Pay in UPAY</button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

    <!--mailer-->

    <Mailer/>

    <!--mailer-->

    <!--footer-->

    <Footer/>

    <!--footer-->
  </div>
</template>

<script>
import Subnav from "@/components/subnav";
import Header from "@/components/header";
import Headingbanner from "@/components/headingbanner";
import Mailer from "@/components/mailer";
import Footer from "@/components/footer";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";

export default {
  name: "product",
  components: {
    Footer,
    Mailer,
    Headingbanner,
    Header,
    Subnav,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        centeredSlides: true,
        // autoplay: {
        //   delay: 4500,
        //   disableOnInteraction: false
        // },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        }
      },
    }
  }
}
</script>

<style scoped>

</style>
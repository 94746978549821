<template>
  <div>
    <!--main-nav-->

    <Header/>

    <!--main-nav-->

    <!--sub-nav-->

    <Subnav/>

    <!--sub-nav-->

    <!--headingbanner-->

    <Headingbanner heading="Gallery"
                   description="Take a look at our top notch and world class designs and we promise you  it will leave you enchanted and mesmerised. The switches are luxurious, miniature and a perfect retrofit for your glamorous home."/>

    <!--headingbanner-->


    <section>
      <div class="container c-pagination">

        <div class="overflow-auto">


          <div>
            <div class="items">

              <div class="row m-0">
                <div class="col-lg-3" v-for="(item, index) in paginatedItems" :key="item.id">
                  <div class="gallery-slider-container my-2 my-lg-1">
                    <a @click="itemClicked(item, index)">
                      <img class="img-fluid" alt="#" :src="item.src"/>
<!--                      <span v-html="item.text"></span>-->
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <b-pagination
              class="paginate-links"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
          />

        </div>
      </div>


    </section>


    <LightBox
        ref="lightbox"
        :media="items"
        :showThumbs="true"
        :showCaption="true"
        :showLightBox="false"
    />
    <Mailer/>
    <Footer/>
  </div>

</template>

<script>
import Subnav from '@/components/subnav';
import Header from '@/components/header';
import Headingbanner from '@/components/headingbanner';
import Mailer from '@/components/mailer';
import Footer from '@/components/footer';
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

export default {
  name: 'gallery',
  components: {
    Footer,
    Mailer,
    Headingbanner,
    Header,
    Subnav,
    LightBox
  },

  methods: {
    itemClicked(item, index) {
      this.$refs.lightbox.onLightBoxOpen();
      this.$refs.lightbox.showImage(index);
    }
  },

  data() {
    var items = [];
    var i;
    for (i = 0; i < 96; i += 1) {
      items.push({
        src: require('./gallery-img.jpg'),
        thumb: require('./gallery-img.jpg'),
        id: i,
        text: 'image ' + (i + 1),
        caption: '<h3>' + 'image ' + (i + 1) +  '</h3>'
      });
    }


    return {
      perPage: 8,
      currentPage: 1,
      items: items
    }
  },
  computed: {
    rows() {
      return this.items.length
    },

    paginatedItems() {
      const current = this.currentPage;
      const arr = [];
      const end = current * this.perPage;
      const start = end - this.perPage;
      let i;

      for (i = start; i < end; i += 1) {
        arr.push(this.items[i]);
      }

      return arr;
    }
  }
}

</script>





<template>
  <!--heading-banner-->

  <section>
    <div class="bg-heading-banner">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="text-white xeo-heading-3 mb-0">{{ heading }}</p>
            <p class="poppins-regular text-white">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--heading-banner-->
</template>

<script>
export default {
  name: "headingbanner",
  props: {
    heading: String,
    description: String
  }
}
</script>

<style scoped>

</style>
<template>
  <section>
    <div class="sub-nav bg-default">
      <div class="container p-0 px-lg-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="d-flex justify-content-between list-unstyled m-0 address-section">
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/locations.png"/>Mannuthy, Thrissur, Kerala</a>
            </li>
            <li class="sub-nav-link">
              <a href="mailto:info@lemonblue.com" target="_blank"><img alt="#" src="../assets/img/mail.png"/>Email:
                info@lemonblue.com</a>
            </li>
            <li class="sub-nav-link">
              <a href="tel:+91 8943 801 853"><img alt="#" src="../assets/img/call.png"/>+91 8943 801 853</a>
            </li>
          </ul>
          <ul class="d-lg-flex justify-content-between list-unstyled m-0 social-media d-none">
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/fb.png"/></a>
            </li>
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/twit.png"/></a>
            </li>
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/linkein.png"/></a>
            </li>
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/insta.png"/></a>
            </li>
            <li class="sub-nav-link">
              <a href="#"><img alt="#" src="../assets/img/social-media/youtube.png"/></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "subnav"
}
</script>

<style scoped>

</style>
<template>
  <!--mailer-->

  <section class="bg-request">
    <div class="container py-5 ">
      <div class="row py-5">
        <div class="col-12 text-center">
          <p class="xeo-heading-3 poppins-semibold">Request a free quote!</p>
          <p class="mb-0 poppins-regular">We'd love to hear from you</p>
        </div>
      </div>
      <div class="row justify-content-center ">
        <div class="col-12 col-lg-7">
          <transition name="fade" mode="out-in">
            <div class="alert alert-success" v-if="state === 'S'" key="'S">
              The mail has been successfully submitted.
            </div>
            <div class="alert alert-danger" v-else-if="state === 'F'" key="'F">
              The mail has been failed.
            </div>
            <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
              <loading-animation />
              Please wait while communicate with the server
            </div>

            <form v-else @submit.prevent="onSubmit">
              <div class="row ">
                <div class="form-group col-lg-6 mb-4">
                  <input type="text" class="form-control xeo-input-1" placeholder="Whats Your Name *" v-model="name">
                </div>
                <div class="form-group col-lg-6 mb-4 xeo-select-1">
                  <select class="form-control" v-model="product">
                    <option>Select a product *</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <input type="email" class="form-control xeo-input-1" placeholder="Your Email *" v-model="email">
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <input type="text" class="form-control xeo-input-1" placeholder="Your Phone Number *"
                         v-model="phoneNo">
                </div>
                <div class="form-group col-lg-12 mb-4">
                            <textarea class="form-control xeo-input-1" placeholder="Tell us about your requirement"
                                      rows="3" v-model="message"></textarea>
                </div>
              </div>
              <div class="justify-content-center mb-5 mt-3 row">
                <div class="col-4 col-lg-3">
                  <button type="submit" class="btn xeo-button-2 btn-block">Submit</button>
                </div>
              </div>


            </form>
          </transition>
        </div>
      </div>
    </div>
  </section>

  <!--mailer-->
</template>

<script>
import axios from 'axios';
import LoadingAnimation from "@/components/LoadingAnimation";

export default {
  name: "mailer",
  components: {LoadingAnimation},
  data() {
    return {
      state: 'D',
      name: '',
      product: '',
      email: '',
      phoneNo: '',
      message: ''
    }
  },

  methods: {
    onSubmit() {
      const bodyFormData = new FormData();
      bodyFormData.append('name', this.name);
      bodyFormData.append('product', this.product);
      bodyFormData.append('message', this.message);
      bodyFormData.append('phoneNo', this.phoneNo);
      bodyFormData.append('email', this.email);

      const that = this;
      this.state = 'L';

      axios({
        method: "post",
        url:"https://lemonblue.in/test.php",
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(function (response) {
        that.onSubmitSuccess(response);
      }).catch(function (response) {
        that.onSubmitFailed(response);
      });
    },

    onSubmitSuccess() {
      this.state = 'S';
    },

    onSubmitFailed() {
      this.state = 'F';
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>